/**
 * Gets the current year.
 *
 * @returns {number} The current year.
 */
export const getCurrentYear = () => new Date().getFullYear();

/**
 * Helper function to fetch data from an API.
 *
 * @param {string} url - The URL to fetch data from.
 * @returns {Promise<Object|null>} The JSON response from the API or null if an error occurs.
 */
const fetchDataFromAPI = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

/**
 * Fetches the current NFL scores from the ESPN API.
 *
 * @returns {Promise<Object|null>} The JSON response from the API or null if an error occurs.
 */
export const fetchCurrentScores = async () => {
  const url = 'https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard';
  return fetchDataFromAPI(url);
};

/**
 * Fetches data for a specific year from the ESPN API.
 *
 * @param {number} year - The year for which to fetch data.
 * @returns {Promise<Object|null>} The JSON response from the API or null if an error occurs.
 */
export const fetchData = async (year) => {
  const url = `https://gambit-api.fantasy.espn.com/apis/v1/challenges/nfl-pigskin-pickem-${year}/groups/57208844-4743-4e42-9690-067090aeb4e4?view=chui_pagetype_group_picks&platform=chui`;
  return fetchDataFromAPI(url);
};

/**
 * Fetches game results data from the ESPN API.
 *
 * @returns {Promise<Object|null>} The JSON response from the API or null if an error occurs.
 */
export const fetchDataGameResults = async () => {
  const currentWeek = getCurrentWeek();
  const filter = {
    filterPropositionScoringPeriodIds: {
      value: [currentWeek]
    }
  }
  const url = `https://gambit-api.fantasy.espn.com/apis/v1/propositions?challengeId=247&platform=chui&view=chui_default&filter=${encodeURIComponent(JSON.stringify(filter))}`;
  return fetchDataFromAPI(url);
};

/**
 * Initializes user data from the provided entries.
 *
 * @param {Object} data - The data containing user entries.
 * @returns {Object} An object with initialized user data.
 */
const initializeUsers = (data) => {
  return data.entries.reduce((acc, score) => {
    acc[displayName[score.name] || score.name] = { points: 0, maxPoints: 0 };
    return acc;
  }, {});
};

const initializeUnDecided = (data) => {
  return data.entries.reduce((acc, score) => {
    acc[displayName[score.name] || score.name] = [];
    return acc;
  }, {});
};

const periodRanges = {
  '1-4': [1, 4],
  '5-8': [5, 8],
  '9-12': [9, 12],
  '13-14': [13, 14],
  '15-18': [15, 18]
};

const displayName = {
  "ESPNFAN7675065558's Picks 1": "Edu's  Picks 1",
  "espn99555048's Picks 1": "Miguel's  Picks 1"
};

/**
 * Gets the losers for a given group.
 *
 * @param {Object} seasonScoreGroups - The season score groups.
 * @param {string} group - The group to get the losers for.
 * @returns {Array} An array of the losers.
 */
export const getLosers = (seasonScoreGroups, group = null) => {
  let sortedNames = [];
  if (group === null) {
    sortedNames = Object.entries(seasonScoreGroups) 
      .sort(([, a], [, b]) => a.points - b.points);
  } else {
    sortedNames = Object.entries(seasonScoreGroups[group])
      .sort(([, a], [, b]) => a.points - b.points);
  }

  let losers = [];
  let i = 0;
  let prevPoints = -1;

  while (i < sortedNames.length && (i < 3 || sortedNames[i][1].points === prevPoints)) {
    losers.push(sortedNames[i][0]);
    prevPoints = sortedNames[i][1].points;
    i++;
  }

  return losers;
};

/**
 * Organizes scores into groups based on the provided raw data and year.
 *
 * @param {Object} rawData - The raw data containing scores.
 * @param {number} year - The year for which to organize scores.
 * @returns {Object|null} An object containing organized scores and losers, or null if no data is available.
 */
export const organizeScores = (rawData) => {
  if (!rawData) {
    console.error('No data available.');
    return null;
  }

  const seasonScoreGroups = Object.keys(periodRanges).reduce((acc, group) => {
    acc[group] = initializeUsers(rawData);
    return acc;
  }, {});

  const undecidedByPlayer = initializeUnDecided(rawData);

  rawData.entries.forEach(score => {
    const user = score.name;
    let isUndecided = false;
    score.picks.forEach(pick => {
      const username = displayName[user] || user;

      isUndecided = pick.outcomesPicked[0].result === 'UNDECIDED';
      if (isUndecided) {
        undecidedByPlayer[username].push(pick.outcomesPicked[0].outcomeId);
      }
    });

    Object.entries(score.score.scoreByPeriod).forEach(([, total], index) => {
      const points = total.score / 10;
      const maxPoints = total.possiblePointsMax / 10;
      const periodNumber = index + 1;

      for (const [group, [start, end]] of Object.entries(periodRanges)) {
        const userName = displayName[user] || user;
        if (periodNumber >= start && periodNumber <= end) {
          seasonScoreGroups[group][userName].points += points;
          seasonScoreGroups[group][userName].maxPoints += maxPoints;
          seasonScoreGroups[group][userName].undecidedIds = undecidedByPlayer[userName];
        }
      }
    });
  });

  const losers = Object.keys(seasonScoreGroups).reduce((acc, group) => {
    acc[group] = getLosers(seasonScoreGroups, group);
    return acc;
  }, {});

  return { seasonScoreGroups, losers };
};

/**
 * Sorts groups based on their start period.
 *
 * @param {Object} groups - The groups to sort.
 * @param {boolean} sortDescending - Whether to sort in descending order.
 * @returns {Array} An array of sorted groups.
 */
export const sortGroups = (groups, sortDescending) => {
  return Object.entries(groups).sort(([a], [b]) => {
    const [aStart] = a.split('-').map(Number);
    const [bStart] = b.split('-').map(Number);
    return sortDescending ? bStart - aStart : aStart - bStart;
  });
};

/**
 * Filters groups to include only those with users who have points greater than 0.
 *
 * @param {Object} groups - The groups to filter.
 * @returns {Object} An object containing the filtered groups.
 */
export const filterGroups = (groups) => {
  return Object.fromEntries(
    Object.entries(groups).filter(([, users]) =>
      Object.values(users).some(user => user.points > 0)
    )
  );
};

/**
 * Filters scores based on a search query.
 *
 * @param {Object} scores - The scores to filter.
 * @param {string} searchQuery - The search query to filter by.
 * @returns {Object} An object containing the filtered scores.
 */
export const filterScores = (scores, searchQuery) => {
  if (!searchQuery) return scores;
  const query = searchQuery.toLowerCase();
  return Object.fromEntries(
    Object.entries(scores).filter(([name]) =>
      name.toLowerCase().includes(query)
    )
  );
};

/**
 * Sorts users by their points in descending order.
 *
 * @param {Object} scores - The scores to sort.
 * @returns {Object} An object containing the sorted users.
 */
export const sortUsersByPoints = (scores) => {
  return Object.entries(scores)
    .sort(([, a], [, b]) => b.points - a.points)
    .reduce((acc, [name, score]) => {
      acc[name] = score;
      return acc;
    }, {});
};

/**
 * Gets the spread value for a team.
 *
 * @param {number} spread - The spread value.
 * @param {boolean} isHome - Whether the team is the home team.
 * @returns {string} The formatted spread value.
 */
const getSpread = (spread, isHome) => {
  const spreadStr = spread.toString();
  const isNegative = spreadStr.includes('-');

  if (isHome) {
    return isNegative ? spreadStr : `+${spreadStr}`;
  } else {
    return isNegative ? spreadStr.replace('-', '+') : `-${spreadStr}`;
  }
};

/**
 * Extracts team data from the provided data.
 * 
 * @param {Array} data - The data to extract team data from.
 * @returns {Array of objects} An array of objects containing team data.
 */
export const extractTeamData = (data) => {
  const currentWeek = getCurrentWeek();
  const result = data
    .filter(item => item.scoringPeriodId === currentWeek)
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map((item) => {
      const outcomes = item.possibleOutcomes.map((outcome) => ({
        team: outcome.name,
        logo: outcome.mappings.find(mapping => mapping.type === 'IMAGE_PRIMARY').value,
        score: outcome.score || 0,
        spread: getSpread(item.spread, outcome.subType === 'HOME'),
        id: outcome.id
      }));
      return {
        match: {
          name: item.name,
          spread: item.spread,
          status: item.status,
          liveScoreURL: item.mappings.find(mapping => mapping.type === 'URL_DESKTOP').value
        },
        outcomes
      };
    });
  return result;
};

/**
 * Extracts match data for a specific short name from the provided data.
 *
 * @param {Object} data - The data to extract match data from.
 * @param {string} shortName - The short name of the match to extract.
 * @returns {Object} The match data for the specified short name.
 */
export const extractMatchData = (data, shortName) => {
  const result = data.events.filter(event => event.shortName === shortName);
  return result[0];
};

/**
 * Calculates the current week of the NFL season based on the number of days since the season start date.
 * The season starts on the Tuesday after Labor Day.
 *
 * @returns {number} The current week of the NFL season, ranging from 1 to 18.
 */
export const getCurrentWeek = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentMonth < 2 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

  // Find the first Monday in September (Labor Day)
  const laborDay = new Date(currentYear, 8, 1);
  while (laborDay.getDay() !== 1) {
    laborDay.setDate(laborDay.getDate() + 1);
  }

  /*
   * Week starts on Tuesday (Labor Day is Monday so Monday + 1 day is Tuesday)
   * 0 - Monday
   * 1 - Tuesday
   * 2 - Wednesday
   * 3 - Thursday
   * 4 - Friday
   * 5 - Saturday
   * 6 - Sunday
   */
  const dayStart = 2;
  const seasonStart = new Date(laborDay.getTime() + dayStart * 24 * 60 * 60 * 1000);
  const timeDiff = currentDate.getTime() - seasonStart.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  const weeksDiff = Math.ceil(daysDiff / 7);

  console.log(Math.max(1, Math.min(weeksDiff, 18)));

  return Math.max(1, Math.min(weeksDiff, 18));
};

export function getYearsFrom2022() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();

  const years = [];

  const endYear = (currentMonth > 2 || (currentMonth === 2 && currentDay >= 1)) ? currentYear : currentYear - 1;

  for (let year = 2022; year <= endYear; year++) {
    years.push(year);
  }

  return years;
}

/**
 * Adds spread coverage information to each team in the game.
 *
 * @param {Object} game - The game object to add spread coverage information to.
 * @returns {Object} The game object with spread coverage information added.
 */
export function addSpreadCoverage(game) {
  game = game.map(match => {
    const [team1, team2] = match.outcomes;

    const spread1 = parseFloat(team1.spread);

    const adjustedScore1 = team1.score + spread1;
    const adjustedScore2 = team2.score;

    team1.isCoveringSpread = adjustedScore1 > adjustedScore2;
    team2.isCoveringSpread = adjustedScore2 > adjustedScore1;
    match.outcomes = [team1, team2];
    return match;
  });
  return game;
}

/**
 * Gets the IDs of matches that are covering the spread.
 *
 * @param {Array} matches - The matches to check for covering spread.
 * @returns {Array} An array of IDs of matches that are covering the spread.
 */
export async function getCoveringSpreadIds(matches) {
  const coveringIds = [];
  const currentScores = await fetchCurrentScores();

  for (const match of matches) {
    let currentMatch = null;
    if (match.match.status === 'LOCKED') {
      currentMatch = await extractMatchData(currentScores, match.match.name);
      currentMatch = currentMatch.competitions[0].competitors;
      match.outcomes[0].score = parseInt(currentMatch[1].score);
      match.outcomes[1].score = parseInt(currentMatch[0].score);
      let updatedMatch = addSpreadCoverage([match])[0];
      updatedMatch.outcomes.forEach(outcome => {
        if (outcome.isCoveringSpread) {
          coveringIds.push(outcome.id);
        }
      });
    }
  }

  return coveringIds;
}
